import React from 'react'
import { UlupinarSliderProps } from './type'
import { useApp } from '@/core/contexts/app'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor'
import { useWidth } from '@/core/hooks/useWidthResize'
import Slider from 'react-slick'
import Image from '@/components/base/image/Image'
import { Container } from '@/components/base/gridview'

const UlupinarGlobal: React.FC<UlupinarSliderProps> = ({
  title,
  description,
  countrys,
  thumbnail,
}) => {
  const width = useWidth()
  const app = useApp()
  return (
    <div className="UlupinarSlider">
      <Container size="medium">
        <div className="UlupinarSlider-head">
          <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
          {/* <Anchor className="more">
          <div
            dangerouslySetInnerHTML={{
              __html: app.settings.translations['moreinfo'],
            }}
          />
          <Icon name="arrow-right-alt" />
        </Anchor> */}
        </div>
        <div
          className="UlupinarSlider-desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="UlupinarGlobal">
          <div className="UlupinarGlobal-left">
            {countrys?.map((item, index) => (
              <div key={index} className="UlupinarGlobal-left-item">
                <Image
                  className="flag"
                  {...item.countryimage}
                  alt={item.countryname}
                />
                <div
                  className="name"
                  dangerouslySetInnerHTML={{ __html: item.countryname }}
                />
              </div>
            ))}
          </div>
          <div className="UlupinarGlobal-right">
            <Image className="thumbnail" {...thumbnail} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default UlupinarGlobal
